import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';
import img from './Logo Final.png';

export default function Navbar() {
  
  function scrollToTop() {
    window.scrollTo(0, 0);
  }
  return (
    <header id="header" class="header d-flex align-items-center fixed-top">
      <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
        <Link to="/" class="logo d-flex align-items-center">
          {/* <img src="assets/img/logo.png" alt="">  */}
          <h1 class="d-flex align-items-center">Luzidcraft</h1>
        </Link>

        <i class="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
        <i class="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>

        <nav id="navbar" class="navbar">
          <ul>
            <li>
              <Link to="/" class="active" onClick={scrollToTop}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={scrollToTop}>
                About
              </Link>
            </li>
            <li class="dropdown">
              <a href="#">
                <span>Services</span>{' '}
                <i class="bi bi-chevron-down dropdown-indicator"></i>
              </a>
              <ul>
                <li>
                  <Link to="/software" onClick={scrollToTop}>
                    Software Development
                  </Link>
                </li>
                <li>
                  <a href="/branding">Branding & Printing</a>
                </li>
                <li>
                  <a href="/business"> Business management </a>
                </li>
                <li>
                  <Link to="/coworking" onClick={scrollToTop}>
                    Co-working Space
                  </Link>
                </li>
              
              </ul>
            </li>
            <li>
              <Link to="/products" onClick={scrollToTop}>
                Products
              </Link>
            </li>

            <li>
              <Link to="/contact" onClick={scrollToTop}>
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}
