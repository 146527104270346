import React from 'react'
import './Scheme.css';


export default function scheme() {
  return (
    <section class="pricing  ">
      <div class="container">
        <div class="row">
          <div class="col-lg-3"></div>
          <div class="col-lg-3">
            <div class="card mb-5 mb-lg-0">
              <div class="card-body">
                <h5 class="card-title text-muted text-uppercase text-center">
                  Basic
                </h5>
                <h6 class="card-price text-center">
                  ₹399<span class="period">/month</span>
                </h6>
                <hr />
                <ul class="fa-ul">
                  <li>
                    <span class="fa-li">
                      <i class="fas fa-check"></i>
                    </span>
                    Customer login
                  </li>
                  <li>
                    <span class="fa-li">
                      <i class="fas fa-check"></i>
                    </span>
                    Customer transactions
                  </li>
                  <li>
                    <span class="fa-li">
                      <i class="fas fa-check"></i>
                    </span>
                    Daily gold rate update
                  </li>
                  <li>
                    <span class="fa-li">
                      <i class="fas fa-check"></i>
                    </span>
                    Commission staff management
                  </li>
                  <li>
                    <span class="fa-li">
                      <i class="fas fa-times"></i>
                    </span>
                    Data backup
                  </li>
                  <li>
                    <span class="fa-li">
                      <i class="fas fa-times"></i>
                    </span>
                    Push notifications
                  </li>
                </ul>
                <div class="d-grid">
                  <a
                    href="tel:9072093850"
                    class="btn btn-primary text-uppercase"
                  >
                    call
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3">
            <div class="card mb-5 mb-lg-0">
              <div class="card-body">
                <h5 class="card-title text-muted text-uppercase text-center">
                  GOLD
                </h5>
                <h6 class="card-price text-center">
                  ₹499<span class="period">/month</span>
                </h6>
                <hr />
                <ul class="fa-ul">
                  <li>
                    <span class="fa-li">
                      <i class="fas fa-check"></i>
                    </span>
                    Customer login
                  </li>
                  <li>
                    <span class="fa-li">
                      <i class="fas fa-check"></i>
                    </span>
                    Customer transactions
                  </li>
                  <li>
                    <span class="fa-li">
                      <i class="fas fa-check"></i>
                    </span>
                    Daily gold rate update
                  </li>
                  <li>
                    <span class="fa-li">
                      <i class="fas fa-check"></i>
                    </span>
                    Commission staff management
                  </li>
                  <li>
                    <span class="fa-li">
                      <i class="fas fa-times"></i>
                    </span>
                    Data backup
                  </li>
                  <li>
                    <span class="fa-li">
                      <i class="fas fa-times"></i>
                    </span>
                    Push notifications
                  </li>
                  <li>
                    <span class="fa-li">
                      <i class="fas fa-times"></i>
                    </span>
                    Online payment
                  </li>
                  <li>
                    <span class="fa-li">
                      <i class="fas fa-times"></i>
                    </span>
                   Product Listing
                  </li>
                </ul>
                <div class="d-grid">
                  <a
                    href="tel:9072093850"
                    class="btn btn-primary text-uppercase"
                  >
                    call
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3"></div>
        </div>
      </div>
    </section>
  );
}
