import React from 'react'
import { Link } from 'react-router-dom';

import img from '../about/about-header.jpg';
import business from './1.jpg';
import coaching from './2.jpg';

export default function Management() {
  return (
    <div>
      
      <div
        class="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div class="container position-relative d-flex flex-column align-items-center">
          <h2> Business management</h2>
          <ol>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li> Business management</li>
          </ol>
        </div>
      </div>
      <section id="business" class="about">
        <div class="container" data-aos="fade-up">
          <div class="row gy-4" data-aos="fade-up">
            <div class="col-lg-4">
              <img src={business} class="img-fluid" alt="" />
            </div>
            <div class="col-lg-8">
              <div class="content ps-lg-5">
                <h3>BUSINESS CONSULTING</h3>
                <p>
                We offers top-of-the-line business consulting services to businesses in order to identify, define, develop and implement strategies that push companies towards growth. Our consultants owing to their in-depth prowess in the domain, always vision the long-term approach. To offer the appropriate and precise plans for your business our deft experts make sure to get a grip on your company’s visions, objectives, and current operation process during our gap analysis step.
                </p>
                <ul>
                  <li>
                    <i class="bi bi-check-circle-fill"></i>Human-Centric Design
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> Business Processes
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i>Product/Service
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="coaching" class="about">
        <div class="container" data-aos="fade-up">
          <div class="row gy-4" data-aos="fade-up">
          
            <div class="col-lg-8">
              <div class="content ps-lg-5">
                <h3>Executive Coaching</h3>
                <p>
                  A Business leadership coaching program by Our team exclusively for CEO's, Directors, Managers or Team leads. This weekend transformation program will equip you to increase your people and performance skills which will directly reflect on your business growth. Learn 21+ practical management tools on 9 areas of your business leadership and the art of leading teams from the best business management experts. Once you complete the program, you will be getting 2 free business consulting meetings to strategize and design your business for accelerated growth.                </p>
                <ul>
                  <li>
                    <i class="bi bi-check-circle-fill"></i>Understanding Leadership
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> Vision & Strategy
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i>People Management
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i>Performance Management
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4">
              <img src={coaching} class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
    
  )
}
