import React from 'react'
import img from './navbar.jpg';
import img1 from './about-header.jpg';
import logo from './logo.jpg';
import brochers from './brochers.jpg';
import flyers from './flyers.jpg';
import office from './office.jpg';
import poster from './poster.jpg';
import signages from './signages.jpg';
import office_brand from './office_brand.jpg';
import website from './website.jpg';
import social_media from './social_media.jpg';


 import './Branding.css';

export default function Branding() {
  return (
    <>
      <div
        class="breadcrumbs-branding d-flex align-items-center"
        style={{ backgroundImage: `url(${img})` }}
      ></div>
      <div
        class="  d-flex align-items-center    "
        // style={{ backgroundImage: `url(${img})` }}
      >
        <img src={img1} class="img-fluid" width="100%" height="100%"></img>
      </div>

      <section id="why-us" class="why-us">
        <div class="container" data-aos="fade-up">
          <div class="section-header">
            <h2>OUR WORKS</h2>
          </div>

          <div class="row g-0" data-aos="fade-up" data-aos-delay="200">
            <div class="col-xl-12  text-center p-black">
              Luzid brands is the place for everything media, digital and
              creative. We are a full-service
              <br />
              advertising & marketing agency with a team of experts dedicated to
              innovation and performance.
            </div>
          </div>
          <div class="row g-0 m-3" data-aos="fade-up" data-aos-delay="200">
            <div class="col-xl-4 p-3 text-center p-black ">
              <div class="card height-card">
                <img class="card-img-top" src={logo} />
                <div class="card-body">
                  <h5 class="card-title bold">Logo Design</h5>
                  <p class="card-text">
                    A widely used and popular logo design style is the one that
                    temphasizes the importance of combining creative and lucid
                    ideas.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xl-4 p-3 text-center p-black">
              <div class="card height-card">
                <img class="card-img-top" src={brochers} />
                <div class="card-body">
                  <h5 class="card-title bold">Brochures</h5>
                  <p class="card-text">
                    We provide innovative and creative brochure designs and
                    catalogue designs to add extra value to your business
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xl-4 p-3 text-center p-black">
              <div class="card height-card">
                <img class="card-img-top" src={flyers} />
                <div class="card-body">
                  <h5 class="card-title bold">Flyers</h5>
                  <p class="card-text">
                    A custom flyer, made with a template or your own design, can
                    catch customers' eyes and promote your brand.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-xl-4 p-3 text-center p-black">
              <div class="card height-card">
                <img class="card-img-top" src={office} />
                <div class="card-body">
                  <h5 class="card-title bold">Office Stationery</h5>
                  <p class="card-text">
                    A professionally designed stationery set can help to create
                    trust and credibility as you grow your business.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-xl-4 p-3 text-center p-black">
              <div class="card height-card">
                <img class="card-img-top" src={poster} />
                <div class="card-body">
                  <h5 class="card-title bold">Posters & Banners</h5>
                  <p class="card-text">
                    A great poster & Banner can help you get the word out about
                    your brand and product.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-xl-4 p-3 text-center p-black">
              <div class="card height-card">
                <img class="card-img-top" src={signages} />
                <div class="card-body">
                  <h5 class="card-title bold">Signage's</h5>
                  <p class="card-text">
                    we offer complete signage solutions such as 3D Letters, LED
                    Screens, Flex Light boxes,{' '}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xl-4 p-3 text-center p-black">
              <div class="card height-card">
                <img class="card-img-top" src={office_brand} />
                <div class="card-body">
                  <h5 class="card-title bold">Office Branding</h5>
                  <p class="card-text">
                    Creative office and corporate branding are perfect for
                    displaying modern business values and beliefs
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xl-4 p-3 text-center p-black">
              <div class="card height-card">
                <img class="card-img-top" src={website} />
                <div class="card-body">
                  <h5 class="card-title bold">Website</h5>
                  <p class="card-text">
                    Creating a digital presence doesn't mean simply building a
                    and waiting for customers to approach your brand{' '}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xl-4 p-3 text-center p-black">
              <div class="card height-card">
                <img class="card-img-top" src={social_media} />
                <div class="card-body">
                  <h5 class="card-title bold">Social media</h5>
                  <p class="card-text">
                    Social media design consists of visually content that is
                    used as a component of digital marketing via a social media
                    platform.{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
