import React from 'react';

import img from './aa.svg';
import img_desk from './desk.png';
import img_mobile from './mob.png';
import img_web from './Web.png';

export default function Services() {
  return (
    <div className="container mt-5 mb-5">
      <h3 className="page-heading">Services</h3>
      <div className="row">
        <div className="col-md-5">
          <div className="row">
            <div className="col-md-3">
              <img
                className="img-fluid"
                src={img_desk}
                alt="triangle with all three sides equal"
                height="500"
                width="600"
              />
            </div>
            <div className="col-md-9">
              <div className="devolopment">
                <h4>Devolopment</h4>
                <p>
                  We offer extensive and advanced development of softwares and
                  application packages. Our Experienced team of developers
                  develop the Applications Securely using all modern tools and
                  technologies.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <img
                className="img-fluid"
                src={img_mobile}
                alt="triangle with all three sides equal"
                height="500"
                width="600"
              />
            </div>
            <div className="col-md-9">
              <div className="devolopment">
                <h4>Mobile Application</h4>
                <p>
                  We build applications with extremely advanced features for
                  major mobile platforms, which is simple, secure and also be
                  accessed anytime from an individuals preferred device.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <img
                className="img-fluid"
                src={img_web}
                alt="triangle with all three sides equal"
                height="500"
                width="600"
              />
            </div>
            <div className="col-md-9">
              <div className="devolopment">
                <h4>Web Devolopment</h4>
                <p>
                  What makes a website best in Class – Clean Layout Design, Rich
                  Graphics and Great Flash components? There is more than that,
                  a professional website is one, which converts visitors to
                  potential customers, achieves your business objectives and
                  creates a great virtual presence for your company over the
                  web.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <img
            className="img-fluid"
            src={img}
            alt="triangle with all three sides equal"
            height="500"
            width="600"
          />
        </div>
      </div>
    </div>
  );
}
