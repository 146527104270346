import React from 'react'
import img from './about-header.jpg';
import custom from './hero-img.png';
import mobile from './mobile.png';
import web from './web.png';
import desktop from './desktop.png';
import { Link } from 'react-router-dom';


export default function Software() {
  return (
    <>
      <div
        class="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div class="container position-relative d-flex flex-column align-items-center">
          <h2>Services</h2>
          <ol>
            <li>
              <Link to="/">Home</Link>
            </li>

            <li>Software</li>
          </ol>
        </div>
      </div>
      <section id="services-cards" class="services-cards">
        <div class="container" data-aos="fade-up">
          <div class="row gy-4">
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <div class="card-item">
                <div class="row">
                  <div class="col-xl-5">
                    <div
                      class="card-bg"
                      style={{ backgroundImage: `url(${custom})` }}
                    ></div>
                  </div>
                  <div class="col-xl-7 d-flex align-items-center">
                    <div class="card-body">
                      <h4 class="card-title">Custom Software Development</h4>
                      <p>
                        We customize all type of software , required for
                        customers for their business , personal and other
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
              <div class="card-item">
                <div class="row">
                  <div class="col-xl-5">
                    <div
                      class="card-bg"
                      style={{ backgroundImage: `url(${mobile})` }}
                    ></div>
                  </div>
                  <div class="col-xl-7 d-flex align-items-center">
                    <div class="card-body">
                      <h4 class="card-title">Mobile App Development</h4>
                      <p>
                        Mobile application development in ANDROID AND IOS.
                        Develop by latest trend of UI and user friendly
                        application for customer needs. We build applications
                        with extremely advanced features for major mobile
                        platforms, which is simple, secure and also be accessed
                        anytime from an individuals preferred device.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="300">
              <div class="card-item">
                <div class="row">
                  <div class="col-xl-5">
                    <div
                      class="card-bg"
                      style={{ backgroundImage: `url(${web})` }}
                    ></div>
                  </div>
                  <div class="col-xl-7 d-flex align-items-center">
                    <div class="card-body">
                      <h4 class="card-title">Web Development</h4>
                      <p>
                        We develop website and web application by requirements.
                        What makes a website best in Class – Clean Layout
                        Design, Rich Graphics and Great Flash components? There
                        is more than that, a professional website is one, which
                        converts visitors to potential customers, achieves your
                        business objectives and creates a great virtual presence
                        for your company over the web.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="400">
              <div class="card-item">
                <div class="row">
                  <div class="col-xl-5">
                    <div
                      class="card-bg"
                      style={{ backgroundImage: `url(${desktop})` }}
                    ></div>
                  </div>
                  <div class="col-xl-7 d-flex align-items-center">
                    <div class="card-body">
                      <h4 class="card-title">DESKTOP APPLICATIONS</h4>
                      <p>
                        Desktop application for user's business , ui and
                        functionality using latest technology. We offer
                        extensive and advanced development of softwares and
                        application packages. Our Experienced team of developers
                        develop the Applications Securely using all modern tools
                        and technologies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
