import React from 'react';
import { Link } from 'react-router-dom';
import img from './blog-header.jpg';
import whyImg from './about.jpg';
import ctaBg from './cta-bg.jpg';

;

export default function Home() {
  return (
    <>
      <section
        id="hero"
        class="hero d-flex align-items-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-4">
              <h2 data-aos="fade-up">Build and grow successful businesses</h2>
              <blockquote data-aos="fade-up" data-aos-delay="100">
                <p>
                  Generate invoices, manage inventory, understand and reach your
                  customers and measure performance with the only all-in-one
                  business management platform.
                </p>
              </blockquote>
              <div class="d-flex" data-aos="fade-up" data-aos-delay="200">
                <a href="#about" class="btn-get-started">
                  Get Started
                </a>
                {/* <a
                  href="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                  class="glightbox btn-watch-video d-flex align-items-center"
                >
                  <i class="bi bi-play-circle"></i>
                  <span>Watch Video</span>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="why-us" class="why-us">
        <div class="container" data-aos="fade-up">
          <div class="section-header">
            <h2>Why Choose Us</h2>
          </div>

          <div class="row g-0" data-aos="fade-up" data-aos-delay="200">
            <div
              class="col-xl-5 img-bg"
              style={{ backgroundImage: `url(${whyImg})` }}
            ></div>
            <div class="col-xl-7 slides  position-relative">
              <div class="slides-1 swiper">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="item">
                      <h3 class="mb-3">High end support</h3>
                      <h4 class="mb-3">
                        We ensure 24/7 support for any project
                      </h4>
                      <p>
                        It's ultimately about making sure customers are
                        successful in solving whatever issues they came to your
                        business to help solve
                      </p>
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="item">
                      <h3 class="mb-3">Client First</h3>
                      <h4 class="mb-3">Customer is king</h4>
                      <p>
                        We ensure customer satisfaction and building project on
                        customer view
                      </p>
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="item">
                      <h3 class="mb-3">Quality in service</h3>
                      <h4 class="mb-3">100%</h4>
                      <p>Provide 100% quality service for product and servi</p>
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="item">
                      <h3 class="mb-3">On time delivery</h3>
                      <h4 class="mb-3">Deliver at on time</h4>
                      <p>We deliver product at on time</p>
                    </div>
                  </div>
                </div>
                <div class="swiper-pagination"></div>
              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </section>
      <section id="services-list" class="services-list">
        <div class="container" data-aos="fade-up">
          <div class="section-header">
            <h2>Our Services</h2>
          </div>

          <div class="row gy-5">
            <div
              class="col-lg-6 col-md-6 service-item d-flex"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="icon flex-shrink-0">
                <i
                  class="bi bi-pc-display-horizontal"
                  style={{ color: '#f57813' }}
                ></i>
              </div>
              <div>
                <h4 class="title">
                  {' '}
                  <Link to="/software" class="stretched-link">
                    SOFTWAE DEVELOPMENT AND SERVICE
                  </Link>
                </h4>
                <p class="description">
                  We develop and deliver best quality softwares for any
                  requirments
                </p>
              </div>
            </div>
            <div
              class="col-lg-6 col-md-6 service-item d-flex"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div class="icon flex-shrink-0">
                <i class="bi bi-building" style={{ color: '#4607d9' }}></i>
              </div>
              <div>
                <h4 class="title">
                <Link to="/branding" class="stretched-link">
                   BRANDING & PRINTING
                  </Link>
                </h4>
                <p class="description">
                Our services include corporate design, events, social media and outdoor graphics . Our digital services can help you make the transition to new media in the right way.
                </p>
              </div>
            </div>

            <div
              class="col-lg-6 col-md-6 service-item d-flex"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="icon flex-shrink-0">
                <i
                  class="bi bi-person-workspace"
                  style={{ color: '#15a04a' }}
                ></i>
              </div>
              <div>
                <h4 class="title">
                  <Link to="/coworking" class="stretched-link">
                    CO WORKING SPACE
                  </Link>
                </h4>
                <p class="description">
                  We provide high quality workspace at affordable price
                </p>
              </div>
            </div>

            <div
              class="col-lg-6 col-md-6 service-item d-flex"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div class="icon flex-shrink-0">
                <i class="bi bi-bar-chart" style={{ color: '#d90769' }}></i>
              </div>
              <div>
                <h4 class="title">
                  <a href="#" class="stretched-link">
                    BUSINESS MANAGEMENT
                  </a>
                </h4>
                <p class="description">
                  Automate / transform your business to digital business, and
                  boost for best digital sale
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="call-to-action"
        class="call-to-action"
        style={{ backgroundImage: `url(${ctaBg})` }}
      >
        <div class="container" data-aos="fade-up">
          <div class="row justify-content-center">
            <div class="col-lg-6 text-center">
              <h3>Automate your business</h3>
              <p>
                Empower of technology in business is powerful tool for your
                strong sale
              </p>
              <a class="cta-btn" href="tel:9072093850">
                Call To Action
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
