import React from 'react';
import { Link } from 'react-router-dom';
import img from './about-header.jpg';
import erp from './erp.png';
import multiple from './multiple.png';
import gold from './gold.svg';
import ecommerce from './e-commerce.png';
import about from './about.jpg';


export default function Products() {
  return (
    <>
      <div
        class="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div class="container position-relative d-flex flex-column align-items-center">
          <h2>Products</h2>
          <ol>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Products</li>
          </ol>
        </div>
      </div>

      <section id="about" class="about">
        <div class="container" data-aos="fade-up">
          <div class="row gy-4" data-aos="fade-up">
            <div class="col-lg-8">
              <div class="content ps-lg-5">
                <h3>E R P</h3>
                <p>
                  Enterprise resource planning [ERP] for small scale and big
                  scale business
                </p>
                <ul>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> Accounting system
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> HR module
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> Complete Report
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> Ease to Use
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4">
              <img src={erp} class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section id="about" class="about">
        <div class="container" data-aos="fade-up">
          <div class="row gy-4" data-aos="fade-up">
            <div class="col-lg-4">
              <img src={multiple} class="img-fluid" alt="" />
            </div>
            <div class="col-lg-8">
              <div class="content ps-lg-5">
                <h3>MULTIPLE BUSINESS MANAGER</h3>
                <p>
                  MULTIPLE BUSINESS MANAGER is the one of the most powerful
                  product of Luzidcraft Business Solutions. Its help to manage
                  one or more business or asset , with just a look
                </p>
                <ul>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> Multiple Business
                    Manage
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i>Business Task
                    management system
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> Personal Income /
                    Expense manager
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> Project and staff
                    management system
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" class="about">
        <div class="container" data-aos="fade-up">
          <div class="row gy-4" data-aos="fade-up">
            <div class="col-lg-8">
              <div class="content ps-lg-5">
                <h3>GOLD AND SILVER PURCHASING SCHEME APPLICATION</h3>
                <p>
                  The purchasing app, is the one of the successful running app
                  of luzidcraft. Its help to manage jewelry purchasing scheme
                  customers and their transactions. Also Manage all staff
                  related purchasing scheme , and get full report
                </p>
                <ul>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> Customer management
                    and their transaction details
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> Staff management
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> Fully report
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> Online payment
                    integration
                  </li>
                  <li>
                    <Link to="/scheme"  >
                      pricing
                    </Link>
                    
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4">
              <img src={gold} class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section id="about" class="about">
        <div class="container" data-aos="fade-up">
          <div class="row gy-4" data-aos="fade-up">
            <div class="col-lg-4">
              <img src={ecommerce} class="img-fluid" alt="" />
            </div>
            <div class="col-lg-8">
              <div class="content ps-lg-5">
                <h3>E-Commerce Apps</h3>
                <p>
                  High – end applications have end-to-end technology solutions
                  to various enterprises. Our development team helps the client
                  to build the application from scratch and made them finished
                  within a short time. We are master in e-commerce and extremely
                  honoured by clients business growth through online.
                </p>
                <ul>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> Variety of Payment
                    Getaways
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> High end Security
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> 24 / 7 Customer
                    Support
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> Ease to Use
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
