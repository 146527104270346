import React from 'react';
import img from './about-header.jpg';
import about from './about.jpg';
import whyImg from '../home/about.jpg';
import ctaBg from '../home/cta-bg.jpg';
import md1 from './team-1.jpg';
import md2 from './team-3.jpg';
import { Link } from 'react-router-dom';
 

export default function About() {
  return (
    <>
      <div
        class="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div class="container position-relative d-flex flex-column align-items-center">
          <h2>About</h2>
          <ol>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>About</li>
          </ol>
        </div>
      </div>
      <section id="about" class="about">
        <div class="container" data-aos="fade-up">
          <div class="row gy-4" data-aos="fade-up">
            <div class="col-lg-4">
              <img src={about} class="img-fluid" alt="" />
            </div>
            <div class="col-lg-8">
              <div class="content ps-lg-5">
                <h3>Grow your business with technology</h3>
                <p>
                  Luzid craft business solution LLP, is large scale business
                  across world and Company has end-to-end expertise and
                  experience in managing the projects right through ideation to
                  fructification. All engagements are undertaken as a
                  partnership, working with the client to establish an
                  understanding of the business needs and requirements and
                  thereafter providing the best means to achieve them.
                </p>
                <ul>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> Well expert team
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> Service oriented
                    successful business
                  </li>
                  <li>
                    <i class="bi bi-check-circle-fill"></i> More happy clients
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="why-us" class="why-us">
        <div class="container" data-aos="fade-up">
          <div class="section-header">
            <h2>Why Choose Us</h2>
          </div>

          <div class="row g-0" data-aos="fade-up" data-aos-delay="200">
            <div
              class="col-xl-5 img-bg"
              style={{ backgroundImage: `url(${whyImg})` }}
            ></div>
            <div class="col-xl-7 slides  position-relative">
              <div class="slides-1 swiper">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="item">
                      <h3 class="mb-3">High end support</h3>
                      <h4 class="mb-3">
                        We ensure 24/7 support for any project
                      </h4>
                      <p>
                        It's ultimately about making sure customers are
                        successful in solving whatever issues they came to your
                        business to help solve
                      </p>
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="item">
                      <h3 class="mb-3">Client First</h3>
                      <h4 class="mb-3">Customer is king</h4>
                      <p>
                        We ensure customer satisfaction and building project on
                        customer view
                      </p>
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="item">
                      <h3 class="mb-3">Quality in service</h3>
                      <h4 class="mb-3">100%</h4>
                      <p>Provide 100% quality service for product and servi</p>
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="item">
                      <h3 class="mb-3">On time delivery</h3>
                      <h4 class="mb-3">Deliver at on time</h4>
                      <p>We deliver product at on time</p>
                    </div>
                  </div>
                </div>
                <div class="swiper-pagination"></div>
              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="call-to-action"
        class="call-to-action"
        style={{ backgroundImage: `url(${ctaBg})` }}
      >
        <div class="container" data-aos="fade-up">
          <div class="row justify-content-center">
            <div class="col-lg-6 text-center">
              <h3>Automate your business</h3>
              <p>
                Empower of technology in business is powerful tool for your
                strong sale
              </p>
              <a class="cta-btn" href="tel:9072093850">
                Call To Action
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="team" class="team">
        <div class="container" data-aos="fade-up">
          <div class="section-header">
            <h2>Managing Directors</h2>
          </div>

          <div class="row gy-4">
            <div class="row gy-4 ">
              <div className="col-3 "> </div>
              <div className="col-3 text-center">
                {' '}
                <div class=" " data-aos="fade-up" data-aos-delay="200">
                  <div class="team-member">
                    <div class="member-img">
                      <img
                        src={md2}
                        class="img-fluid"
                        alt=""
                        style={{ height: '110px' }}
                      />
                      <div class="social">
                        {/* <a href="">
                      <i class="bi bi-twitter"></i>
                    </a>
                    <a href="">
                      <i class="bi bi-facebook"></i>
                    </a> */}
                        <a href="https://www.instagram.com/salman_luzid/">
                          <i class="bi bi-instagram"></i>
                        </a>
                        {/* <a href="">
                          <i class="bi bi-linkedin"></i>
                        </a> */}
                      </div>
                    </div>
                    <div class="member-info">
                      <h4>SALMAN FARIS</h4>
                      {/* <span>MANAGING DIRECTOR</span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 text-center">
                {' '}
                <div class=" " data-aos="fade-up" data-aos-delay="200">
                  <div class="team-member">
                    <div class="member-img">
                      <img
                        src={md2}
                        class="img-fluid"
                        alt=""
                        style={{ height: '110px' }}
                      />
                      <div class="social">
                        {/* <a href="">
                      <i class="bi bi-twitter"></i>
                    </a> */}
                    {/* <a href="">
                      <i class="bi bi-facebook"></i>
                    </a> */}
                        <a href="https://www.instagram.com/mushthaque_luzidcraft/">
                          <i class="bi bi-instagram"></i>
                        </a>
                        {/* <a href="">
                          <i class="bi bi-linkedin"></i>
                        </a> */}
                      </div>
                    </div>
                    <div class="member-info">
                      <h4>MUSHTAQUE</h4>
                      {/* <span>MANAGING DIRECTOR</span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3"> </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
