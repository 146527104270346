import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer id="footer" class="footer">
      <div class="footer-content">
        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-5 col-md-12 footer-info">
              <Link to="/" class="logo d-flex align-items-center">
                <span>Luzidcraft</span>
              </Link>
              <p>
                Luzidcraft support to your business managing without your
                physical presents
              </p>
              <div class="social-links d-flex  mt-3">
                <a href="#" class="twitter">
                  <i class="bi bi-twitter"></i>
                </a>
                <a href="https://www.facebook.com/luzidcraft/" target="_blank" class="facebook">
                  <i class="bi bi-facebook"></i>
                </a>
                <a href="https://www.instagram.com/luzidcraft/" target="_blank" class="instagram">
                  <i class="bi bi-instagram"></i>
                </a>
                <a href="https://www.linkedin.com/in/lucid-craft-801b69229/" target="_blank" class="linkedin">
                  <i class="bi bi-linkedin"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i class="bi bi-dash"></i> <Link to="/">Home</Link>
                </li>
                <li>
                  <i class="bi bi-dash"></i> <Link to="/about">About us</Link>
                </li>
                <li>
                  <i class="bi bi-dash"></i>{' '}
                  <Link to="/software">Services</Link>
                </li>
                <li>
                  <i class="bi bi-dash"></i> <Link to="/products">Product</Link>
                </li>
                <li>
                  <i class="bi bi-dash"></i>{' '}
                  <Link to="/contact">Contact us</Link>
                </li>
              </ul>
            </div>

            <div class="col-lg-2 col-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i class="bi bi-dash"></i> <a href="#">Web Design</a>
                </li>
                <li>
                  <i class="bi bi-dash"></i> <a href="#">Web Development</a>
                </li>
                <li>
                  <i class="bi bi-dash"></i> <a href="#">Product Management</a>
                </li>
                <li>
                  <i class="bi bi-dash"></i> <a href="#">ERP</a>
                </li>
                <li>
                  <i class="bi bi-dash"></i> <a href="#">Desktop app</a>
                </li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contact Us</h4>
              <p>
                Luzid Craft Software Solutions <br />
                near Hilite mall,Calicut
                <br />
                Kerala <br />
                <br />
                <strong>Phone:</strong> +91 907 209 3850
                <br />
                <strong>
                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                </strong>{' '}
                +91 701 209 3850
                <br />
                <strong>Email:</strong> info@luzidcraft.com
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-legal">
        <div class="container">
          <div class="copyright">
            &copy; Copyright{' '}
            <strong>
              <span>Luzid Craft</span>
            </strong>
            . All Rights Reserved
          </div>
          <div class="credits">
            Designed by{' '}
            <a href="https://www.luzidcraft.com/">www.luzidcraft.com</a>
          </div>
        </div>
      </div>
    </footer>
  );
}
