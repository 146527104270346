import React from 'react'
import img from './about-header.jpg';
import spaceImage from './work.jpg';
import { Link } from 'react-router-dom';

export default function Coworking() {
  return (
    <>
      <div
        class="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div class="container position-relative d-flex flex-column align-items-center">
          <h2>Services</h2>
          <ol>
            <li>
              <Link to="/">Home</Link>
            </li>

            <li>Co-Working Space</li>
          </ol>
        </div>
      </div>
      <section id="features" class="features">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-7" data-aos="fade-up" data-aos-delay="100">
              <h3>
               Provide space <br />
               For coworking
              </h3>

              <div class="row gy-4">
                <div class="col-md-6">
                  <div class="icon-list d-flex">
                    <i class="ri-store-line" style={{ color: ' #ffbb2c' }}></i>
                    <span>Less amount</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="icon-list d-flex">
                    <i
                      class="ri-bar-chart-box-line"
                      style={{ color: ' #5578ff' }}
                    ></i>
                    <span>High speed internet connection</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="icon-list d-flex">
                    <i
                      class="ri-calendar-todo-line"
                      style={{ color: ' #e80368' }}
                    ></i>
                    <span>Air conditional space</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="icon-list d-flex">
                    <i
                      class="ri-paint-brush-line"
                      style={{ color: ' #e361ff' }}
                    ></i>
                    <span>Include water and sitting space</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="icon-list d-flex">
                    <i
                      class="ri-database-2-line"
                      style={{ color: ' #47aeff' }}
                    ></i>
                    <span>100% Security</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="icon-list d-flex">
                    <i
                      class="ri-gradienter-line"
                      style={{ color: ' #ffa76e' }}
                    ></i>
                    <span>Address virtual office</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="icon-list d-flex">
                    <i
                      class="ri-file-list-3-line"
                      style={{ color: ' #11dbcf' }}
                    ></i>
                    <span>Confidentiality</span>
                  </div>
                </div>

                 
              </div>
            </div>
            <div
              class="col-lg-5 position-relative"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="phone-wrap">
                <img src={spaceImage} alt="Image" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        <div class="details">
          <div class="container" data-aos="fade-up" data-aos-delay="300">
            <div class="row">
              <div class="col-md-6">
                <h4>
               Perfect space in
                  <br />
                Low cost
                </h4>
                <p>
                  We provide virtual office / Co working space for freelancers / other workers.
                  Providing premium space at cheapest cost in calicut city
                </p>
                <a href="tel:9072093850" class="btn-get-started">
                  Book Now
                </a>
              </div>
            </div>
          </div>
              </div>
              
      </section>
    </>
  );
}
