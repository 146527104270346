import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import 'antd/dist/antd.css';

import Navbar from './components/layout/Navbar';
import Home from './components/home/Home';
import Footer from './components/layout/Footer';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Services from './components/services/Services';
import Products from './components/products/Products';
import Software from './components/Software/Software';
import Coworking from './components/Coworking/Coworking';
import Branding from './components/Branding/Branding';
import Management from './components/BusinessManagement/Management';
import Scheme from './components/products/jwellery/scheme';



require('dotenv').config();

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Switch>
          <div>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/software" component={Software} />
            <Route exact path="/coworking" component={Coworking} />
            <Route exact path="/branding" component={Branding} />
            <Route exact path="/business" component={Management} />
            <Route exact path="/scheme" component={Scheme}/>
             
          </div>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
