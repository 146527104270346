import React, { useState } from 'react';
import Axios from '../../axios/axios';
import { message } from 'antd';
import { Link } from 'react-router-dom';
import img from './contact-header.jpg';

export default function Contact() {
  const [formFields, setFormFields] = useState({
    name: '',
    subject: '',
    email: '',
    remark: '',
    phone: '',
    whatsapp: '',
  });

  const { name, subject, email, remark, phone, whatsapp } = formFields;

  const onChange = (e) => {
    setFormFields({ ...formFields, [e.target.name]: e.target.value });
  };

  const clearState = () => {
    setFormFields({
      name: '',
      subject: '',
      email: '',
      remark: '',
      phone: '',
      whatsapp: '',
    });
  };

  const key = 'updatable';
  const onSubmit = async (e) => {
    e.preventDefault();

    message.loading({ content: 'Loading...', key });

    const res = await Axios.post(`/api/sent-mail/`, formFields);

    if (res.status === 200) {
      message.success(
        'thanks for reaching out!  our team will get back in touch with you soon! Have a great day!',
        3
      );
      clearState();
    }
  };

  return (
    <>
      <div
        class="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div class="container position-relative d-flex flex-column align-items-center">
          <h2>Contact</h2>
          <ol>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Contact</li>
          </ol>
        </div>
      </div>
      <section id="contact" class="contact">
        <div class="container position-relative" data-aos="fade-up">
          <div class="row gy-4 d-flex justify-content-end">
            <div class="col-lg-5" data-aos="fade-up" data-aos-delay="100">
              <div class="info-item d-flex">
                <i class="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h4>Location:</h4>
                  <p> Near Hilite mall ,Calicut </p>
                </div>
              </div>

              <div class="info-item d-flex">
                <i class="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h4>Email:</h4>
                  <p>info@luzidcraft.com</p>
                </div>
              </div>

              <div class="info-item d-flex">
                <i class="bi bi-phone flex-shrink-0"></i>
                <div>
                  <h4>Call:</h4>
                  <p>+91 907 2093 850</p>
                  <p>+91 701 2093 850</p>
                </div>
              </div>
            </div>

            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="250">
              <form
                onSubmit={onSubmit}
                method="post"
                role="form"
                class="php-email-form"
              >
                <div class="row">
                  <div class="col-md-6 form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Your Name"
                      name="name"
                      onChange={(e) => onChange(e)}
                      value={name}
                      required={true}
                    />
                  </div>
                  <div class="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      class="form-control"
                      name='email'
                      placeholder="Your Email"
                      onChange={(e) => onChange(e)}
                      value={email}
                      required={true}
                    />
                  </div>
                </div>
                <div class="form-group mt-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Mobile number"
                    name="phone"
                                      onChange={(e) => onChange(e)}
                                      value={phone}
                                      required={true}
                  />
                </div>
                <div class="form-group mt-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Subject"
                    name="subject"
                    onChange={(e) => onChange(e)}
                    value={subject}
                  />
                </div>
                <div class="form-group mt-3">
                  <textarea
                    class="form-control"
                    rows="5"
                    placeholder="Message"
                    name="remark"
                    onChange={(e) => onChange(e)}
                    value={remark}
                  ></textarea>
                </div>
                <div class="my-3">
                  <div class="loading">Loading</div>
                  <div class="error-message"></div>
                  <div class="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
    // <div>
    //   <div className="container mt-5 mb-5">
    //     <div>{/* <h3 className="card-title">Get in touch</h3> */}</div>
    //     <h3 className="page-heading">Contact Us</h3>

    //     <div className="row">
    //       <div className="col-md-4">
    //         {' '}
    //         <div className="card mt-2">
    //           <div className="card-body">
    //             <form id="myform" className="col s12" onSubmit={onSubmit}>
    //               <div className="form-group">
    //                 <label className="font-weight-bold">Your Name</label>
    //                 <input
    //                   type="text"
    //                   className="form-control"
    //                   placeholder="Enter Your Name"
    //                   name="name"
    //                   onChange={(e) => onChange(e)}
    //                   value={name}
    //                   required={true}
    //                 ></input>
    //               </div>
    //               <div className="form-group">
    //                 <label className="font-weight-bold">Subject</label>
    //                 <input
    //                   type="text"
    //                   className="form-control"
    //                   placeholder="Enter Subject"
    //                   name="subject"
    //                   onChange={(e) => onChange(e)}
    //                   value={subject}
    //                 ></input>
    //               </div>
    //               <div className="form-group">
    //                 <label className="font-weight-bold">Email address</label>
    //                 <input
    //                   type="email"
    //                   className="form-control"
    //                   placeholder="Enter email"
    //                   name="email"
    //                   onChange={(e) => onChange(e)}
    //                   value={email}
    //                   required={true}
    //                 ></input>
    //                 <small className="form-text text-muted">
    //                   We'll never share your email with anyone else.
    //                 </small>
    //               </div>
    //               <div className="form-group">
    //                 <label className="font-weight-bold">Phone Number</label>
    //                 <input
    //                   type="number"
    //                   className="form-control"
    //                   placeholder="Enter Number"
    //                   name="phone"
    //                   onChange={(e) => onChange(e)}
    //                   value={phone}
    //                   required={true}
    //                 ></input>
    //               </div>
    //               <div className="form-group">
    //                 <label className="font-weight-bold">Whatsapp Number</label>
    //                 <input
    //                   type="number"
    //                   className="form-control"
    //                   placeholder="Enter Whatsapp Number"
    //                   name="whatsapp"
    //                   onChange={(e) => onChange(e)}
    //                   value={whatsapp}
    //                 ></input>
    //               </div>
    //               <div className="form-group">
    //                 <label className="font-weight-bold">Message</label>
    //                 <textarea
    //                   className="form-control"
    //                   id="message"
    //                   rows="3"
    //                   name="remark"
    //                   onChange={(e) => onChange(e)}
    //                   value={remark}
    //                 ></textarea>
    //               </div>
    //               {/* <input type="submit" className="btn green" value="Confrim" /> */}
    //               <button
    //                 type="submit"
    //                 className="btn btn-blue"
    //                 value="Confrim"
    //               >
    //                 Send
    //               </button>
    //             </form>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-md-4">
    //         <div className="card mt-2">
    //           <div className="card-body">
    //             <p className="card-text">Lucid Craft Software Solutions</p>
    //             <p className="card-text">mail.lucidcraft@gmail.com</p>
    //             <p className="card-text"> +91 9072093850</p>
    //             <p className="card-text"> +91 7012093850</p>
    //             <p className="card-text"> Calicut, Kerala </p>
    //           </div>
    //         </div>
    //       </div>

    //       <div className="col-md-4 mt-5">
    //         {' '}
    //         <img
    //           className="img-fluid"
    //           src={img}
    //           alt="triangle with all three sides equal"
    //           height="500"
    //           width="600"
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
